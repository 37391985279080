<template>
  <div class="setting">
    <p class="font-title">基础设置</p>

    <a-row class="row-deep">
      <a-col :span="12" class="blod">姓名</a-col>
      <a-col :span="12" class="blod">邮箱</a-col>
      <a-col :span="12">
        <a-input v-model="info.userName" style="width:80%"></a-input>
      </a-col>
      <a-col :span="12">
        <a-input v-model="info.email" style="width:80%"></a-input>
      </a-col>
    </a-row>

    <p class="baseBtn">
      <a-button type="primary" @click="update">更新信息</a-button>
    </p>

    <p class="font-title">安全设置</p>

    <a-row type="flex" justify="space-between" align="middle" style="margin-bottom: 42px">
      <a-col :span="12">
          <b>账户密码</b>
          <!-- <p>当前密码强度：<span class="font-suc">强</span></p> -->
      </a-col>
      <a-col>
          <a @click="editPwd.off = true">修改密码</a>
      </a-col>
      <a-divider />

      <a-col :span="12">
          <b>登录手机</b>
      </a-col>
      <a-col>
        <a-space :size="24">
          <span class="blod">{{info.regMobile}}</span>
          <a @click="editPhone.off = true">修改手机</a>
        </a-space>
      </a-col>
      <a-divider />
    </a-row>

    <!-- <p class="font-title">账号绑定</p>

    <a-row type="flex" justify="space-between" align="middle" style="margin-bottom: 42px">
      <a-col :span="12">
          <div class="bind-content">
              <img src="../../assets/images/u4197.jpg">
          </div>
          
          <div class="bind-content">
            <b>绑定微信</b>
            <p>绑定支付宝</p>
          </div>
      </a-col>
      <a-col>
          <a>绑定</a>
      </a-col>
      <a-divider />

      <a-col :span="12">
          <div class="bind-content">
              <img src="../../assets/images/u4205.png">
          </div>
          
          <div class="bind-content">
            <b>绑定微信</b>
            <p>绑定支付宝</p>
          </div>
      </a-col>
      <a-col>
          <a>绑定</a>
      </a-col>
      <a-divider />
    </a-row> -->
    <a-modal v-model="editPwd.off" title="修改密码" centered :footer="null" class="edit-pwd" @cancel="resart()">
      <div class="l-flex-allcenter">
          <uInput v-model="editPwd.code" :hasClear="false" max="6" placeholder="短信验证码" :class="codeError && 'verify-color'" @focus="codeError = false">
              <span :class="editPwd.switchCode && 'verify-code'" @click="sendMsg">{{editPwd.count === 60 ? '发送验证码' : `${editPwd.count}秒后重发`}}</span>
              <template #icon>
              <a-icon type="message" />
              </template>
          </uInput>
          <div v-if="codeError" class="verify-text">验证码不能为空</div>
      </div>
      <div class="l-flex-allcenter">
          <uInput
          type="password"
          max="20"
          :sign="true"
          :isPwd='false'
          :class="pwdError && 'verify-color'"
          v-model="editPwd.password"
          placeholder="设置6至20位登录密码"
          @focus="pwdError = false"
          @clear="editPwd.password = $event">
              <template #icon>
                  <a-icon type="lock" />
              </template>
          </uInput>
          <div v-if="pwdError" class="verify-text">请输入正确的密码</div>
      </div>

      <div class="l-flex-allcenter">
          <uInput
          type="password"
          :class="error && 'verify-color'"
          max="20"
          :sign="true"
          :isPwd='false'
          v-model="editPwd.passwordVerify"
          @focus="error = false"
          placeholder="再次输入密码"
          @clear="editPwd.passwordVerify = $event">
              <template #icon>
                  <a-icon type="lock" />
              </template>
          </uInput>
          <div v-if="error" class="verify-text">与输入的新密码不一致</div>
      </div>

      <p class="l-flex-allcenter" style="margin-top: 24px">
          <a-button type="primary" @click="enterEditPwd">确认修改</a-button>
      </p>
    </a-modal>

    <a-modal v-model="editPhone.off" title="修改手机号" centered :footer="null" class="edit-pwd" @cancel="resart()">
      <p class="l-flex-allcenter">
        <uInput
        ref="code"
        :sign="true"
        :max="11"
        v-model="editPhone.phone"
        placeholder="输入手机号码"
        @clear="editPhone.phone = $event">
            <template #icon>
                <a-icon type="mobile" />
            </template>
        </uInput>
      </p>
      <p class="l-flex-allcenter">
          <uInput v-model="editPhone.code" :hasClear="false" max="6" placeholder="短信验证码">
              <span :class="editPhone.switchCode && 'verify-code'" @click="sendMsgPhone">{{editPhone.count === 60 ? '发送验证码' : `${editPhone.count}秒后重发`}}</span>
              <template #icon>
              <a-icon type="message" />
              </template>
          </uInput>
      </p>
      <p class="l-flex-allcenter">
          <uInput v-model="editPhone.oldCode" :hasClear="false" max="6" placeholder="当前手机号短信验证码">
              <span :class="oldVerify.switchCode && 'verify-code'" @click="sendMsgOldPhone">{{oldVerify.count === 60 ? '发送验证码' : `${oldVerify.count}秒后重发`}}</span>
              <template #icon>
              <a-icon type="message" />
              </template>
          </uInput>
      </p>

      <p class="l-flex-allcenter">
          <a-button type="primary" @click="enterEditPhone">确认修改</a-button>
      </p>
    </a-modal>
  </div>
</template>

<script>
import {info,update,accountSms,saveNewPwd,editNewPhone,saveNewPhone,editPhoneSms} from '@/api/user'
import uInput from '@/components/uInput'
const regEmail = /.{1,}@.{1,}\..{1,}/
import md5 from 'js-md5'

export default {
  components: {uInput},
  data() {
    return {
      pwdError: false,
      codeError: false,
      error: false,
      info: {
        regMobile: '',
        userName: '',
        email: ''
      },
      editPwd: {
          off: false,
          switchCode: false,
          count: 60,
          code: '',
          password: '',
          passwordVerify: ''
      },
      editPhone: {
          off: false,
          switchCode: false,
          count: 60,
          phone: '',
          code: '',
          oldCode: ''
      },
      oldVerify: {
        switchCode: false,
        count: 60,
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      info().then(res => {
        this.info = {...this.info,...res.data}
      })
    },
    update() {
      if (!this.info.userName) {
        this.$message.error('姓名不能为空')
        return
      } else if (!regEmail.test(this.info.email)) {
        this.$message.error('请输入正确的邮箱')
        return
      }
      update({userName: this.info.userName, email: this.info.email}).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
        }
      })
    },
    // 获取修改密码验证码
    sendMsg() {
      if (!this.editPwd.switchCode) {
        accountSms().then(res => {
          if (res.code === 200) {
              this.editPwd.switchCode = true
              this.countDown('editPwd')
            } else {
              this.$message.error(res.message)
            }
        })
      } 
    },
    // 获取新手机号验证码
    sendMsgPhone() {
      if (!this.editPhone.switchCode) {
        editNewPhone(this.editPhone.phone).then(res => {
          if (res.code === 200) {
              this.editPhone.switchCode = true
              this.countDown('editPhone')
            } else {
              this.$message.error(res.message)
            }
        })
      } 
    },
    // 获取老手机号验证码
    sendMsgOldPhone() {
      if (!this.oldVerify.switchCode) {
        editPhoneSms().then(res => {
          if (res.code === 200) {
              this.oldVerify.switchCode = true
              this.countDown('oldVerify')
            } else {
              this.$message.error(res.message)
            }
        })
      } 
    },
    countDown(str) {
      if (this[str].count > 0 && this[str].switchCode) {
        this[str].count--
        const that = this
        setTimeout(function(){that.countDown(str)}, 1000)
      } else {
        this[str].switchCode = false
        this[str].count = 60
      }
    },
    // 修改密码
    enterEditPwd() {
      if (!this.editPwd.code) {
        this.codeError = true
        return
      } else if (this.editPwd.password.length<6) {
        this.pwdError = true
        return
      } else if (this.editPwd.passwordVerify !== this.editPwd.password) {
        this.error = true
        return
      }
      const data = {
        code: this.editPwd.code,
        password: md5(this.editPwd.password)
      }
      saveNewPwd(data).then(res => {
        if (res.code === 200) {  
          this.$message.success(res.message)
          this.resart()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 修改手机号
    enterEditPhone() {
      if (!this.editPhone.phone) {
        this.$message.error('手机号不能为空')
        return
      } else if (!this.editPhone.code) {
        this.$message.error('验证码不能为空')
        return
      } else if (!this.editPhone.oldCode) {
        this.$message.error('当前手机验证码不能为空')
        return
      }
      const data = {
        newCode: this.editPhone.code,
        oldCode: this.editPhone.oldCode,
        newPhone: this.editPhone.phone
      }
      saveNewPhone(data).then(res => {
        if (res.code === 200) {  
          this.$message.success(res.message)
          this.init()
          this.resart()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    resart() {
      this.pwdError = false
      this.codeError = false
      this.error = false
      this.editPwd = {
          off: false,
          switchCode: false,
          count: 60,
          code: '',
          password: ''
      }
      this.editPhone = {
          off: false,
          switchCode: false,
          count: 60,
          phone: '',
          code: '',
          password: ''
      }
      this.oldVerify = {
        switchCode: false,
        count: 60,
      }
    }
  }
}
</script>

<style lang="less" scoped>
b {
    margin-bottom: 8px;
    display: inline-block;
}
.blod {
  font-weight: bold;
}
.setting {
  font-size: 14px;
  .baseBtn {
    margin-bottom: 42px;
  }
  .bind-content {
    display: inline-block;
    vertical-align: middle;
    img {
        height: 50px;
        width: 50px;
        margin-right: 16px;
        border-radius: 4px;
    }
  }
}
.font-suc {
    color: #52C41A;
}
.font-title {
  font-weight: 650;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  margin-bottom: 24px;
}
.row-deep {
  margin-bottom: 20px;
  /deep/ .ant-col {
    margin-bottom: 10px;
  }
}
.edit-pwd {
    .l-flex-allcenter {
        margin-bottom: 20px;
        position: relative;
        span {
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            color: @primary;
        }
        .verify-text {
          position: absolute;
          font-size: 14px;
          color: @error;
          bottom: -20px;
          left: 88px;
        }
        .verify-color {
          background: #fde3e4;
          border-color: #e33e33;
        }
        .verify-code {
            color: #cccccc;
        }
        &:nth-child(1) {
            font-size: 16px;
        }
    }
}
</style>